import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useId, useMemo, useState } from 'react';

const Input = ({
    type = 'text',
    value,
    label,
    placeholder,
    onChange,
    error,
    className,
    ...rest
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const id = useId();

    const innerType = useMemo(() => {
        if (type !== 'password') {
            return type;
        }

        return isVisible ? 'text' : 'password';
    }, [type, isVisible]);

    const cls = classNames(
        'w-full rounded-xl p-3.5',
        className,
        {
            'focus:ring-primary-200': !error,
        },
        {
            'text-red-800': error,
            'border-red-800': error,
            'bg-red-50': error,
            'placeholder-red-800': error,
            'focus:ring-red-800': error,
            'focus:border-red-800': error,
        }
    );

    return (
        <div className='relative mb-5'>
            {label && (
                <label htmlFor={id} className='mb-1 block'>
                    {label}
                </label>
            )}
            <input
                className={cls}
                type={innerType}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                id={id}
                {...rest}
            />
            {type === 'password' && (
                <>
                    {isVisible ? (
                        <EyeSlashIcon
                            className='absolute top-1/4 right-3 h-6 w-6 cursor-pointer'
                            onClick={() => setIsVisible(false)}
                        />
                    ) : (
                        <EyeIcon
                            className='absolute top-1/4 right-3 h-6 w-6 cursor-pointer'
                            onClick={() => setIsVisible(true)}
                        />
                    )}
                </>
            )}

            {error && (
                <p className='mt-1 text-xs font-bold text-red-800'>{error}</p>
            )}
        </div>
    );
};

export default Input;
