import classNames from 'classnames';

const Button = ({
    content,
    variant = 'primary',
    loading = false,
    className,
    as,
    childAs,
    ...attributes
}) => {
    const RenderAs = as ? as : 'button';

    const cls = classNames(
        'text-sm cursor-pointer transition-colors ease-in-out text-center disabled:cursor-not-allowed',
        {
            'w-full': variant !== 'link',
        },
        {
            'w-full text-white font-bold bg-primary-500 p-3.5 rounded-xl disabled:bg-primary-200':
                variant === 'primary',
            'hover:bg-primary-200 hover:focus:outline-primary-200':
                variant === 'primary',
            'focus:outline-offset-2 focus:outline-primary-500':
                variant === 'primary',
        },
        {
            'w-full text-primary-500 font-bold bg-white p-3.5 rounded-xl border-2 border-primary-500 disabled:text-primary-200 disabled:bg-gray-50 disabled:border-primary-200':
                variant === 'secondary',
            'hover:text-primary-200 hover:border-primary-200':
                variant === 'secondary',
            'focus:outline-hidden': variant === 'secondary',
        },
        {
            'border-0 p-0 text-primary-500': variant === 'link',
            'hover:text-primary-200': variant === 'link',
            'focus:outline-0 focus:text-primary-200': variant === 'link',
        },
        className
    );

    return (
        <RenderAs
            {...attributes}
            className={cls}
            disabled={attributes.disabled || loading}
            as={childAs}
        >
            {content}
        </RenderAs>
    );
};

export default Button;
